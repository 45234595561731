<template>
<div>
 <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
      <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  <v-data-table
    :headers="headers"
    :items="publication_category_super_master_list"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Publication Category</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details>
          </v-text-field>
          <span style="width: 20px"></span>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Add Publication Category</v-btn>
          </template>
          <v-card>
            <v-form v-model="valid">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field dense
                      v-model="editedItem.publication_category_super_master"
                      item-text="publication_category_super_master_list_id"
                      item-value="id"
                      persistent-hint
                      :hint="editedIndex==-1?'For example :: Book, Paper':'For example :: Book, Paper'"
                      label="User Type"
                      :error="errorMsg.publication_category_super_master?true:false"
                      :error-messages="errorMsg.publication_category_super_master?'Required':''"
                       :rules="[v => !!v || 'required']"
                    ></v-text-field>
                  </v-col>
                   <v-col cols="12" sm="6" md="4" v-if='editedIndex != -1'>
                    <v-checkbox
                        dense
                        v-model="editedItem.isactive"
                        color="success"
                        label="Active"
                      ></v-checkbox>
                  </v-col>     
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="primary darken-1"  @click="save" :disabled="!valid">Save</v-btn>
            </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

         <template v-slot:item.isactive="{ item }">
            <span v-if="item.isactive">
              <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
            </span>
            <span v-else>
              <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
            </span>
          </template>

    <template v-slot:item.action="{ item }">
     <div>
        <v-avatar style="" size="25" class="edit-avatar">
          <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
       </v-avatar>
      </div>
    </template>
  </v-data-table>
  <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</div>
</template>

<script>
import axios from 'axios'
  export default {
    data: () => ({
       valid: false,
       overlay: false,
       errorMsg:{
       publication_category_id:false
      },
       snackbar_msg: "",
       color: "",
       snackbar: false,
       search:'',
       dialog: false,
       headers: [
        {
          text: 'Publication Category',align: 'left',sortable: false,value: 'publication_category_super_master',
        },
        {
          text: 'Active',align: 'left',sortable: true,value: 'isactive',
        },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      publication_category_super_master_list: [],
      editedIndex: -1,
      editedItem: {
        id: '',
        publication_category_super_master: '',
        isactive:true  
      },
      defaultItem: {
        id: '',
        publication_category_super_master: '',
        isactive:true      
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Publication Category' : 'Edit Publication Category'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
        overlay (val) {
        val= () => {
          this.overlay = false
        };
      },
    },

    // created () {
    //   this.initialize()
    // },
    mounted(){
       this.onLoad()
     },
    methods: {
    //   initialize () {
        
    //   },

      editItem (item) {
        this.editedIndex = this.publication_category_super_master_list.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      onLoad(){
      this.overlay=true;
      axios
      .post("/Admin/adminPublicationCategory")
      .then(res => {
        if (res.data.msg == "200") {
          //window.alert(res.data.msg)
          if(this.load = true){
            this.overlay = false;
            this.publication_category_super_master_list = res.data.publication_category_super_master_list
          }
        } else {
          window.console.log(res.data.msg)
        }
      })
      .catch(error => {
        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        // window.console.log(error);
      })
      .finally(()=>{ 
        var overlay=false;
      });       
    },
      showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
       },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.editedIndex > -1) 
        {
          if(this.isValidated())
         {
          axios
          .post("/Admin/editAdminPublicationCategory", this.editedItem)
          .then(res => {
            if (res.data.status.code == "SUCCESS") {
              //window.alert(res.data.msg)
              Object.assign(this.publication_category_super_master_list[this.editedIndex], this.editedItem);
              this.showSnackbar(
                  "#4caf50",
                  "Publication Category Updated Successfully..."
                ); // show snackbar on success
            }
              else if(res.data.status.code == "NA"){
                this.showSnackbar("#b71c1c", "Publication Category Already Present!!!"); // show snackbar on error
            }
          })
          .catch(error => {
            window.console.log(error);
          });
            this.close()
         }
        } 
        else 
        {
          this.publication_category_super_master_list.push(this.editedItem)
           axios
          .post("/Admin/saveAdminPublicationCategory", this.editedItem)
          .then(res => {
            if (res.data.status.code == "SUCCESS") {
            //window.alert(res.data.status.code)
            this.publication_category_super_master_list.push(this.editedItem)
             this.showSnackbar("#4caf50", "Publication Category Added Successfully..."); // show snackbar on success
             this.onLoad()    
            }
            else if(res.data.status.code == "NA"){
                //window.alert(res.data.status.code)
               // alert(res.data.status.message)
                this.showSnackbar("#b71c1c", "Publication Category Already Present!!!"); // show snackbar on error
            }
          })
          .catch(error => {
            window.console.log(error);
          });
          this.close()
        }
      }, //save()
      isValidated(){
      if(this.editedItem.publication_category_id)
       { 
           return true; 
       }
      else{
            if(!this.editedItem.publication_category_id)
            {
              this.errorMsg.publication_category_id=true
            }
           
        return false;
      }  
     }, // .....end of isValidated()
    },
  }
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size{
  font-size: 20px;
}
.edit-avatar{
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover{
  background-color: #b0bec5;
}
.edit-v-icon{
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover{
  color: white;
}
</style>